import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeBackground from "../components/Visuals/homeBackground"
import JumboComponent from "../components/JumboComponent"
import JobCard from "../components/JobCard"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "reactstrap"

import HomeContactComponent from "../components/HomeContactComponent"

const Welcome = props => (
  <Col
    xs={12}
    md={8}
    className="d-flex"
    style={{ marginTop: "6rem", marginBottom: "3rem" }}
  >
    <div className="m-auto">
      <h1 style={{ color: "rgb(171, 74, 156)", fontSize: "3rem" }}>
        Szeretnél egy prosperáló társadalmi vállalkozásnál dolgozni?
      </h1>

      <p>
        Régóta olyan munkára vágysz, ahol nemcsak pénzt kereshetsz, hanem
        valóban pozitív hatással lehetsz a környezetre és társadalmunkra?
      </p>
      <p>Akkor van egy jó hírünk, a Cseriti pont egy ilyen munkahely!</p>
      <p>
        Nézz körül aktuális állásajánlataink között, és jelentkezz hozzánk,
        örömmel várunk!
      </p>
    </div>
  </Col>
)

const NoJobs = props => (
  <Col xs={12} md={8} className="my-4 d-flex" style={{ height: 400 }}>
    <div className="m-auto text-center">
      <h1 style={{ color: "rgb(171, 74, 156)", fontSize: "3rem" }}>
        Ritka pillanat! <br />
        Jelenleg nincs betölthető, nyitott pozíciónk.
      </h1>

      <p>
        Kérjük, nézz vissza később és addig is látogass el a hozzád legközelebbi
        Cseritibe!
      </p>
    </div>
  </Col>
)
const KarrierPage = props => {
  const { i18n } = useTranslation("common")
  const language = i18n.language
  const pageData = props.data.sanityHomePage
  const karrierData = props.data.sanityKarrierPage
  const allJobs = props.data.allSanityJob.edges
  const pageTitle = "Állásajánlatok"
  const seoImage = getImage(
    pageData?.mainImages[0]?.asset || pageData?.mainImages[1]?.asset
  )?.images.fallback.src

  return (
    <Layout alternateLink={props.alternateLinks}>
      <HomeBackground />
      <Container>
        <Seo
          title={`Cseriti - ${pageTitle}`}
          lang={language}
          image={seoImage}
        />
        <JumboComponent
          images={karrierData.image}
          slogan={pageTitle}
          singleImage={true}
        />
        <Row className="justify-content-center">
          <Welcome></Welcome>
        </Row>
        <Row className="justify-content-center">
          {allJobs.filter(j => j.node.isPublic).length > 0 ? (
            allJobs.map(
              job =>
                job.node.isPublic && (
                  <Col xs={12} md={4} className="mt-1 mb-5">
                    <JobCard job={job.node} />
                  </Col>
                )
            )
          ) : (
            <NoJobs></NoJobs>
          )}
        </Row>
      </Container>
      <HomeContactComponent intro={pageData._rawIntro[language]} />
    </Layout>
  )
}

export const query = graphql`
  query KarrierPageQuery {
    sanityHomePage(_id: { eq: "d653ce08-c183-4893-a241-7b5d7d931ee6" }) {
      id
      _rawIntro
      mainImages {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      _rawSlogan
    }
    sanityKarrierPage {
      image {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
    allSanityJob {
      edges {
        node {
          slug {
            current
          }
          place
          jobType
          isPublic
          description {
            _rawChildren
            children {
              marks
              text
            }
            style
          }
          id
          title
        }
      }
    }
  }
`
export default KarrierPage
