import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import { MdLocationOn, MdAccessTimeFilled } from "react-icons/md"

const StyledJobCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  height: 100%;
  box-shadow: 0 0 15px #50355044, 0 0 25px #6a3f6d44;
  padding: 2rem;
  .round-button {
    border-radius: 10px;
    font-size: 1.6rem;
    text-transform: uppercase;
    padding: 0.5rem 2rem;
    letter-spacing: 2px;
  }
  .purple-button {
    background: rgb(73, 71, 157);
    border: rgb(73, 71, 157);
    &:hover {
      background: rgb(43, 41, 160);
      border: rgb(43, 41, 160);
    }
  }
`

const JobCard = props => {
  const { job } = props
  const { i18n } = useTranslation("common")
  const language = i18n.language
  return (
    <StyledJobCard>
      <Link to={`/${language}/karrier/${job.slug.current}`}>
        <h1>{job.title}</h1>
      </Link>

      <p>
        <MdLocationOn />
        {` `}
        {job.place}
      </p>
      <p>
        <MdAccessTimeFilled />
        {` `}
        {job.jobType === "full" ? "Teljes munkaidő" : "Részmunkaidő"}
      </p>
      <div>
        <Link
          to={`/${language}/karrier/${job.slug.current}`}
          className="btn btn-info mr-3 purple-button round-button"
        >
          Részletek
        </Link>
      </div>
    </StyledJobCard>
  )
}

JobCard.propTypes = {
  job: PropTypes.object,
}

export default JobCard
